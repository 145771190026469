@use '../mixins';

.btn {
    &_light {
        @include mixins.buttons(#0e699c, #EFEFEF, #EFEFEF);
        @include mixins.box();
        width: 25px;
        height: 25px;
        padding: 2px !important;
        fill: #0e699c !important;
    }

    &_link {
        @include mixins.buttons($fontColor, $transparent, $transparent);
    }

    &_link_underline {
        @include mixins.buttons($darkblue, $transparent, $transparent);
        text-decoration: underline;
    }
}

.btn.btn {
    $btn_padding: 10px 20px !important;

    &_auth_submit {
        @include mixins.btn();
        width: 80%;
        background-color: #0E699C;
        color: $white;
        margin: auto;

        &:hover {
            color: $white;
            background: #0976a7 !important;
        }
    }

    &_update {
        @include mixins.btn();
        padding: $btn_padding;
        background-color: $mainblue;
        color: $white;
        font-size: .875rem !important;

        &:hover {
            color: $white;
            background: #49BDDF !important;
        }
    }

    &_primary {
        @include mixins.btn();
        padding: $btn_padding;
        background-color: $mainblue;
        color: $white;
        font-size: .875rem !important;

        &:hover {
            color: $white;
            background: #49BDDF !important;
        }
    }

    &_outline {
        @include mixins.btn();
        padding: $btn_padding;
        border: 1px solid $mainblue;
        background-color: transparent;
        color: $mainblue;
        font-size: .875rem !important;

        &:hover {
            color: #49BDDF !important;
            border: 1px solid #49BDDF !important;
        }
    }

    &_secondary {
        @include mixins.btn();
        padding: $btn_padding;
        border: 1px solid $mainblue;
        background-color: transparent;
        color: $mainblue;
        font-size: .875rem !important;

        &:hover {
            color: #49BDDF !important;
            border: 1px solid #49BDDF !important;
        }
    }

    &_outline_primary {
        @include mixins.btn();
        padding: $btn_padding;
        border: 1px solid $mainblue;
        background-color: transparent;
        color: $mainblue;
        font-size: .875rem !important;

        &:hover {
            color: #49BDDF !important;
            border: 1px solid #49BDDF !important;
        }
    }
}

.btn.filter_btn_search {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0BADD7;
    border: 1px solid #0BADD7;
    color: #ffffff;
    padding: 0.5rem !important;
    svg{
        fill: #ffffff;
    }
}

.btn.filter_btn_reload {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #0BADD7;
    color: #0BADD7;
    font-size: 19px;
}

.btn.validate_btn{
    background: #ebebeb;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    fill: #4d4d4d;    
}


.custom-head-card {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: $radius_normal;
    box-shadow: $shadow_title_block;
    padding: 0.5rem 1rem;
    border-top: 4px solid $mainblue;
    background-color: $white;
    justify-content: center;
    height: 62px;

    .h3 {
        margin-bottom: 0px;
        font-size: $mainHeading;
        font-weight: $semi_bold;
    }

    .custom-div-search {
        display: flex;
        gap: 1rem;
    }
}
.searchinput {
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #ccc;
    height: 42px;
    display: flex;
    align-items: center;
    width: 20rem;
    position: relative;

    @media screen and (max-width: $tablet) {
        width: 10rem;
    }
    @media screen and (max-width: $mobile) {
        width: 100%;
    }            

    input.form_control {
        font-size: $medium_small;
        font-weight: $normal;
        background-color: $transparent;
        border: 0;
        padding: 0.375rem 0.75rem;
        background-clip: padding-box;
        line-height: 1.5;
        width: 100%;
        display: block;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-radius: $radius_normal;

        &:focus {
            outline: none;
            border: 0;
        }
    }

    .search_icon {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        font-size: 0.813rem;
        display: inline-flex;
        padding: 7px 10px;

    }
}

@mixin buttons($color, $background, $border, $font_size: 0.875rem, $radius: 4px) {
    color: $color !important;
    background-color: $background !important;
    font-size: $font_size !important;
    font-weight: 500 !important;
    border-radius: $radius !important;
    border-color: $border !important;
    &:hover{
      color: $color !important;
      background-color: $background !important;
      border-color: $border !important;
    }
    &:focus{
        color: $color !important;
        background-color: $background !important;
        border-color: $border !important;
    }
}

@mixin btn(){
  border-radius: 4px;
  height: 42px;
  font-weight: 600 !important;
  cursor: pointer;    
}

@mixin box( $display:flex, $align-items: center, $justifyContent: center, $flexDirection: row ){
  display: $display !important;
  align-items: $align-items !important;
  justify-content: $justifyContent !important;
  flex-direction: $flexDirection !important;
}


@mixin fontcontent($clamp:1,$display:-webkit-box, $maxWidth:unset){
  -webkit-line-clamp: $clamp;
  display: $display;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $maxWidth;
}

@mixin textOverflow(){
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
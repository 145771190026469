@import './scss/vars';
@import './scss/components/buttons.scss';
@import './scss/components/page_header.scss';
@import './scss/general.scss';
@import './scss/components/sidebar.scss';
@import './scss/components/opcard.scss';
@import './scss/components/apiscripting.scss';
@import './scss/components/input.scss';



.text-danger {
  color: red
}

.error {
  color: red;
  font-size: .8rem;
}

.drawerClose .sideMenu ul.mainmenu {
  overflow: hidden;
  z-index: -999;
}

// Delete Popup Styles
.custom_modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: rgba($color: #000000, $alpha: 0.15);
  display: flex;
  justify-content: center;
  align-items: center;

  .del_modal {
    height: auto;
    background-color: #fff;
    border-radius: 0 0 14px 14px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    width: 40vw;

    @media screen and (max-width: $tablet) {
      width: 600px;
    }

    @media screen and (max-width: $mobile) {
      width: 100%;
      margin: 0 1rem;
    }

    .modal_header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F0F8FF;
      border-radius: 0;

      &-title {
        color: #000000;
        font-weight: 500;
        font-size: 14px;
      }

      .close {
        background: transparent;
        border: 0;
        font-size: 20px;
      }
    }

    .modal_body {
      border-radius: 0 0 4px 4px;

      .deleteModalContent {
        font-size: 15px;
        font-weight: 500;
        color: #363636;
      }

      .deleteModalContentMessage {
        font-size: 11px;
        font-weight: 500;
        color: #fa0e0e;
      }
    }
  }
}

.form-control {
  box-shadow: none !important;
  font-size: .875rem;
  padding: 10px 20px;
  height: 42px;
}

.form-select {
  box-shadow: none !important;
  border: 1px solid #b6b6b6;
  padding: 10px 20px;
  height: 42px;
  font-size: .875rem;
  background-image: url('../assets/images/other/filter_icon.svg') !important;

  &:focus {
    border: 1px solid #b6b6b6;
    background-color: #FFFFFF !important;
  }

  &.invalid {
    border: 1px solid #FF0000 !important;
  }
}


// Page Empty Section
.empty_section {
  height: calc(100vh - 196px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// End of Page Empty Section

// card Layout 
.op_card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000000D;
  border: 0 !important;
  border-radius: 4px;
}

// End of Card Layout


// filter section
.filter_section {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;

  button {
    img {
      width: 25px;
    }
  }
}

// end of Filter Section



label.form-label {
  margin-bottom: 2px;
}


input.form-control {
  border: 1px solid #b6b6b6;
}

// Disable Eye Button in Edge
input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}


// Pagination
.align-left {
  float: left;
}

.Listing_pagination {
  .page-link {
    padding: 0.175rem 0.75rem;
    color: #393939;
    font-weight: 500;
    font-size: 14px;
  }

  .page-link:focus {
    color: #0d6efd;
    box-shadow: none;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #393939;
    background-color: #e9ecef;
    border-color: #e9ecef;
  }
}

.cardCount {
  font-size: .875rem;
  color: #363636;
  font-weight: 500;
}

.btn-cancel {
  background-color: #EBEBEB;
  border: 1px solid #B6B6B6AC;
  border-radius: 4px;
  font-size: .8rem;
  font-weight: 500;
  color: #000000;

  &:hover {
    background-color: #EBEBEB;
    border: 1px solid #B6B6B6AC;
    color: #000000;
  }
}

.btn-save {
  color: #ffffff;
  background-color: #1CACD7;
  font-size: .8rem;
  font-weight: 500;
  border-radius: 4px;
  border-color: #1CACD7;

  &:hover {
    color: #ffffff;
    background-color: #1CACD7;
    border-color: #1CACD7;
  }
}

.btn-disable {
  background-color: #B6B6B6 !important;
  color: #828282 !important;
}




.paginationBlock {
  .MuiPagination-ul {
    justify-content: flex-end !important;
  }
}

.Mui-selected {
  background-color: #0BADD7 !important;
  border: 1px solid #0BADD7 !important;
  color: #fff !important;
}

table {
  thead {
    background-color: #EFEFEF;
  }

  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.passwordtearms {
  padding: .5rem;

  p {
    margin: 0;
    font-size: .75rem;
    color: #333333;
    font-weight: 500;
  }

  ul {
    padding-left: .8rem;
    list-style-type: disc !important;

    li {
      font-size: .75rem;
      color: #333333;
      font-weight: 400;
    }
  }
}

.live_result_progress {
  position: relative;

  .vertical_line {
    position: absolute;
    border-left: 1px solid #6B6B6B;
    height: 30px;
  }

  .vertical_line_right {
    position: absolute;
    border-left: 1px solid #6B6B6B;
    height: 30px;
    right: 0;
  }

  .progress_clock {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;

    li {
      color: #6B6B6B;
      font-size: 0.625rem;
      font-weight: 400;
      padding-left: 0.3rem;
      margin: 0 1rem 0 0;
    }
  }

  .progress {
    height: 2.063rem;
    font-size: 0.75rem;
    background-color: #EBEBEB;
    border-radius: 30px;
    position: relative;
    border: 0.5px solid #828282;

    .progress-bar {
      background: transparent linear-gradient(270deg, #97DD40 0%, #36A41D 100%) 0% 0% no-repeat padding-box;
      background-color: transparent;
    }
  }
}

.apmSpinner_button {
  width: 1rem;
  height: 1rem;
  color: #0BADD7;
}

// Testcase modal

.testcaseModal {
  max-width: 1250px;

  .tc_form_op {
    overflow: auto;
  }

  @media screen and (max-width: 1200px) {
    max-width: calc(100% - 2rem);
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 350px) {
    max-width: calc(100% - 1rem);
  }

  @media screen and (min-width: 1380px) {
    max-width: 1350px;
  }

  @media screen and (min-width: 1500px) {
    max-width: 90%;
  }

  .modal-header {
    background: #f0f8ff !important;
    position: sticky;
    top: 0;
    z-index: 99999;

    .modal-title {
      font-size: 15px;
    }

    .btn-close {
      font-size: 10px;
      box-shadow: none;
    }
  }

  .modal-body {
    .createTestButtonPanel {
      min-height: 27rem;
      display: flex;
      align-items: center;
    }

    .parameter_list_table {
      .table {
        box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
        background-color: #ffffff !important;

        thead {
          background-color: #EFEFEF;

          th {
            padding: 0.5rem;
            font-size: 14px;
            font-weight: 500;
            color: #363636;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            background-color: #EFEFEF !important;
          }
        }

        tbody {
          td {
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .upload_content {
      font-size: 9px;
      font-weight: 500;
      left: 5px;
      color: #757575;
      position: absolute;
    }

    .error {
      font-size: .6rem;
    }

    .filenameWrapper {
      width: 77.5%;

      .inputplaceholder {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media screen and (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 99999;
    background-color: #f7f7f7;
  }
}
#spinner-fade {
    background-color: #ababab;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    opacity: .60;
}

.default-spinner {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
}
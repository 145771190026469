@use '../mixins';

.op_card{
    &_title{
       color: $font_title;
       font-size: 1rem;
       font-weight: $medium;
       @include mixins.textOverflow();
    }
    &_thumbnail{
        color: $light_green;
        background: $secondary;
        border-radius: $radius_normal;
        padding: 0;
        width: 32px;
        height: 32px;
        @include mixins.box(inline-flex);
        text-align: center;
        text-transform: uppercase;
    }     
    &_created{
       color: $fontSecondary;
       font-size: $smaller_font;
       font-weight: $medium;
       display: flex;
       align-items: center;
    }
    &_description{
       @include mixins.fontcontent(4);
       color: $fontSecondary;
       font-size: $medium_content;
       font-weight: $normal;
       text-align: justify;
    }
    &_block{
        line-height: 1.5;
    }
    &_label{
        font-size: $smaller_font;
        font-weight: $medium;
        color: $fontSecondary;
    }
    &_value{
        font-size: $smaller_font;
        font-weight: $medium;
        color: $black;
        @include mixins.fontcontent(1, unset);
        white-space: nowrap;
    }
    &_value_domain{
        font-size: $smaller_font;
        font-weight: $medium;
        color: $black;
        @include mixins.fontcontent(unset,block,100%);
        white-space: nowrap;
    }
}